dmx.Component('notifications', {

  attributes: {
    position: {
      type: String,
      default: 'top',
      enum: ['top', 'bottom'],
    },

    align: {
      type: String,
      default: 'end',
      enum: ['left', 'right', 'full', 'start', 'center', 'end', 'stretch'],
      // start, end, center, stretch are new options, left, right and are deprecated but still supported
    },

    offsetX: {
      type: Number,
      default: 15,
    },

    offsetY: {
      type: Number,
      default: 15,
    },

    spacing: {
      type: Number,
      default: 10,
    },

    opacity: {
      type: Number,
      default: 0.8,
    },

    timeout: {
      type: Number,
      default: 5000, // How long the notify will display without any interaction (0 to make sticky)
    },

    extendedTimeout: {
      type: Number,
      default: 1000,
    },

    showAnimation: {
      type: String,
      default: 'fadeIn',
    },

    showDuration: {
      type: Number,
      default: 400,
    },

    hideAnimation: {
      type: String,
      default: 'fadeOut',
    },

    hideDuration: {
      type: Number,
      default: 400,
    },

    closeAnimation: {
      type: String,
      default: 'fadeOut',
    },

    closeDuration: {
      type: Number,
      default: 400,
    },

    closeIcon: {
      type: String,
      default: 'fa fa-times',
    },

    closable: {
      type: Boolean,
      default: false,
    },

    infoBackground: {
      type: String,
      default: '#2f96b4',
    },

    infoColor: {
      type: String,
      default: '#fff',
    },

    infoIcon: {
      type: String,
      default: 'fa fa-info',
    },

    successIcon: {
      type: String,
      default: 'fa fa-check',
    },

    successBackground: {
      type: String,
      default: '#51a351',
    },

    successColor: {
      type: String,
      default: '#fff',
    },

    warningIcon: {
      type: String,
      default: 'fa fa-exclamation',
    },

    warningBackground: {
      type: String,
      default: '#f89406',
    },

    warningColor: {
      type: String,
      default: '#fff',
    },

    dangerIcon: {
      type: String,
      default: 'fa fa-warning',
    },

    dangerBackground: {
      type: String,
      default: '#bd362f',
    },

    dangerColor: {
      type: String,
      default: '#fff',
    },
  },

  methods: {
    clear () {
      this._clear();
    },

    msg (message, options) {
      this._show('msg', message, options);
    },

    info (message, options) {
      this._show(
        'info',
        message,
        Object.assign(
          {
            icon: this.props.infoIcon,
            color: this.props.infoColor,
            background: this.props.infoBackground,
          },
          options
        )
      );
    },

    success (message, options) {
      this._show(
        'success',
        message,
        Object.assign(
          {
            icon: this.props.successIcon,
            color: this.props.successColor,
            background: this.props.successBackground,
          },
          options
        )
      );
    },

    warning (message, options) {
      this._show(
        'warning',
        message,
        Object.assign(
          {
            icon: this.props.warningIcon,
            color: this.props.warningColor,
            background: this.props.warningBackground,
          },
          options
        )
      );
    },

    danger: function (message, options) {
      this._show(
        'danger',
        message,
        Object.assign(
          {
            icon: this.props.dangerIcon,
            color: this.props.dangerColor,
            background: this.props.dangerBackground,
          },
          options
        )
      );
    },
  },

  events: {
    click: CustomEvent,
  },

  init () {
    this._alignMap = {
      left: 'flex-start',
      right: 'flex-end',
      full: 'stretch',
      start: 'flex-start',
      center: 'center',
      end: 'flex-end',
      stretch: 'stretch',
    };
  },

  render (node) {
    node.classList.add('dmx-notifications');
    node.setAttribute('aria-live', 'polite');
    node.setAttribute('aria-atomic', 'true');

    this._clear();
    this._update();
  },

  performUpdate (updatedProps) {
    this._update();
  },

  _clear: function () {
    this.$node.textContent = '';
  },

  _update () {
    this.$node.style.setProperty('--dmx-notify-offset-x', this.props.offsetX + 'px');
    this.$node.style.setProperty('--dmx-notify-offset-y', this.props.offsetY + 'px');
    this.$node.style.setProperty('--dmx-notify-align', this._alignMap[this.props.align]);
    this.$node.style.setProperty('--dmx-notify-gap', this.props.spacing + 'px');
    this.$node.style.setProperty('--dmx-notify-direction', this.props.position == 'top' ? 'column' : 'column-reverse');
  },

  _template (options) {
    let tpl = `<div class="dmx-notify" role="alert" aria-live="assertive" aria-atomic="true" style="--dmx-notify-background: ${options.background}; --dmx-notify-color: ${options.color}; --dmx-notify-opacity: ${options.opacity}">`;
    if (options.icon) tpl += `<div class="dmx-notify-icon"><i class="${options.icon}"></i></div>`;
    tpl += `<div class="dmx-notify-body">`;
    if (options.title) tpl += `<div class="dmx-notify-title">${options.title}</div>`;
    if (options.message) tpl += `<div class="dmx-notify-message">${options.message}</div>`;
    tpl += `</div>`;
    if (options.closable) tpl += `<button type="button" aria-label="Close" class="dmx-notify-close"><i class="${options.closeIcon}"></i></button>`;
    tpl += `</div>`;

    return tpl;
  },

  _create: function (options) {
    var template = this._template(options);
    var div = document.createElement('div');
    div.innerHTML = template;
    return div.firstChild;
  },

  _show: function (type, message, options) {
    options = Object.assign(
      { type: type, message: message, background: '#eee', color: '#333' },
      this.props,
      options
    );

    var notify = this._create(options);

    notify.style.setProperty('animation-name', options.showAnimation);
    notify.style.setProperty('animation-duration', options.showDuration + 'ms');

    notify.addEventListener('click', this.dispatchEvent.bind(this, 'click', { detail: options }));

    if (options.closable) {
      notify.querySelector('.dmx-notify-close').addEventListener('click', this._close.bind(this, notify, options));
    } else if (!options.timeout) {
      // if not closable and not timeout, hide after 5 seconds
      options.timeout = 5000;
    }

    if (options.timeout) {
      notify.addEventListener('mouseenter', this._pause.bind(this, notify, options));
      notify.addEventListener('mouseleave', this._continue.bind(this, notify, options));
      notify.timeoutId = setTimeout(this._hide.bind(this, notify, options), options.showDuration + options.timeout);
    }

    this.$node.insertBefore(notify, this.$node.firstChild);
  },

  _hide: function (notify, options) {
    if (options.hideAnimation && options.hideDuration) {
      notify.style.setProperty('animation-name', options.hideAnimation);
      notify.style.setProperty('animation-duration', options.hideDuration + 'ms');
      notify.addEventListener('animationend', () => notify.remove(), { once: true });
    } else {
      notify.remove();
    }
  },

  _close: function (notify, options) {
    if (options.closeAnimation && options.closeDuration) {
      notify.style.setProperty('animation-name', options.closeAnimation);
      notify.style.setProperty('animation-duration', options.closeDuration + 'ms');
      notify.addEventListener('animationend', () => notify.remove(), { once: true });
    } else {
      notify.remove();
    }
  },

  _pause: function (notify, options) {
    clearTimeout(notify.timeoutId);
  },

  _continue: function (notify, options) {
    if (options.timeout || options.extendedTimeout) {
      notify.timeoutId = setTimeout(this._hide.bind(this, notify, options), options.extendedTimeout);
    }
  },
});
